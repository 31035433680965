var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "data-cy": "company-users-view"
    }
  }, [_c('TabTitle', {
    staticClass: "mb-4",
    attrs: {
      "icon": "fa-users",
      "action-position": "right"
    },
    scopedSlots: _vm._u([{
      key: "sub-title",
      fn: function fn() {
        return [_vm._v("List of users that belong to this company.")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('router-link', {
          staticClass: "btn btn-primary",
          attrs: {
            "to": {
              path: "/company/users/create"
            },
            "data-cy": "add-user-btn"
          }
        }, [_c('i', {
          staticClass: "fa fa-plus mr-1"
        }), _vm._v(" Add User ")])];
      },
      proxy: true
    }])
  }, [[_vm._v("Users")]], 2), _c('div', {
    staticClass: "block-content block-content-full"
  }, [_c('DataTable', {
    attrs: {
      "data-cy": "user-table",
      "items": _vm.sortedUsers,
      "total": _vm.usersTotal,
      "loading": _vm.loadingAction.listUsers,
      "columns": _vm.columns,
      "actions": _vm.actions,
      "page-size": 20,
      "fetch-items": _vm.listUsers,
      "other-params": _vm.usersParams,
      "default-sort-key": "email",
      "default-sort-direction": 1
    },
    scopedSlots: _vm._u([{
      key: "welcomeEmailSent",
      fn: function fn(_ref) {
        var data = _ref.data;
        return [data.welcomeEmailSent ? _c('span', {
          staticClass: "badge badge-success"
        }, [_vm._v("Sent")]) : _c('span', {
          staticClass: "badge badge-danger"
        }, [_vm._v("Not Sent")])];
      }
    }])
  }), _c('ConfirmModal', {
    attrs: {
      "id": "remove",
      "open": !!_vm.modals.remove,
      "title": "Delete Supplier",
      "text": "Please confirm you would like to remove user:<br/><br/><strong>".concat(_vm.modals.remove.name, "</strong>")
    },
    on: {
      "close": function close($event) {
        _vm.modals.remove = false;
      },
      "submit": _vm.onDeleteUser
    }
  }), _c('ConfirmModal', {
    attrs: {
      "open": !!_vm.modals.welcomeEmail,
      "title": "Send Welcome Email",
      "text": "Please confirm you would like to send a welcome email to ".concat(_vm.modals.welcomeEmail.name, ". The email will contain their password, and a button to login to Etainabl.")
    },
    on: {
      "close": function close($event) {
        _vm.modals.welcomeEmail = false;
      },
      "submit": function submit($event) {
        return _vm.onSendWelcome(_vm.modals.welcomeEmail);
      }
    }
  }), _c('ConfirmModal', {
    attrs: {
      "id": "reset-password",
      "open": !!_vm.modals.resendPassword,
      "title": "Reset Password",
      "text": "Please confirm you would like to reset the password for ".concat(_vm.modals.resendPassword.name, ". An email will be sent to the user containing a new password.")
    },
    on: {
      "close": function close($event) {
        _vm.modals.resendPassword = false;
      },
      "submit": function submit($event) {
        return _vm.onSendWelcome(_vm.modals.resendPassword);
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }