<template>
  <div data-cy="company-users-view">
    <TabTitle class="mb-4" icon="fa-users" action-position="right">
      <template>Users</template>
      <template #sub-title>List of users that belong to this company.</template>
      <template #actions>
        <router-link class="btn btn-primary" :to="{ path: `/company/users/create` }" data-cy="add-user-btn">
          <i class="fa fa-plus mr-1"></i> Add User
        </router-link>
      </template>
    </TabTitle>
    <div class="block-content block-content-full">
      <DataTable
        data-cy="user-table"
        :items="sortedUsers"
        :total="usersTotal"
        :loading="loadingAction.listUsers"
        :columns="columns"
        :actions="actions"
        :page-size="20"
        :fetch-items="listUsers"
        :other-params="usersParams"
        default-sort-key="email"
        :default-sort-direction="1"
      >
        <template #welcomeEmailSent="{ data }">
          <span v-if="data.welcomeEmailSent" class="badge badge-success">Sent</span>
          <span v-else class="badge badge-danger">Not Sent</span>
        </template>
      </DataTable>
      <ConfirmModal
        id="remove"
        :open="!!modals.remove"
        title="Delete Supplier"
        :text="`Please confirm you would like to remove user:<br/><br/><strong>${modals.remove.name}</strong>`"
        @close="modals.remove = false"
        @submit="onDeleteUser"
      />
      <ConfirmModal
        :open="!!modals.welcomeEmail"
        title="Send Welcome Email"
        :text="`Please confirm you would like to send a welcome email to ${modals.welcomeEmail.name}. The email will contain their password, and a button to login to Etainabl.`"
        @close="modals.welcomeEmail = false"
        @submit="onSendWelcome(modals.welcomeEmail)"
      />
      <ConfirmModal
        id="reset-password"
        :open="!!modals.resendPassword"
        title="Reset Password"
        :text="`Please confirm you would like to reset the password for ${modals.resendPassword.name}. An email will be sent to the user containing a new password.`"
        @close="modals.resendPassword = false"
        @submit="onSendWelcome(modals.resendPassword)"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import ConfirmModal from '@/components/ConfirmModal';
import DataTable from '@/components/DataTable';
import TabTitle from '@/components/base/TabTitle';

export default {
  name: 'CompanyUsersList',
  components: {
    ConfirmModal,
    DataTable,
    TabTitle
  },
  data() {
    return {
      modals: {
        remove: false,
        welcomeEmail: false,
        resendPassword: false
      },
      sentEmails: [],
      dataTableKey: 0,
      entityOptions: [],
      usersParams: {
        $populate: 'role',
        isAdmin: false
      }
    };
  },
  computed: {
    ...mapGetters({
      company: 'company/company',
      users: 'user/users',
      roles: 'role/roles',
      usersTotal: 'user/usersTotal',
      loadingAction: 'company/loadingAction',
      errorAction: 'company/errorAction'
    }),
    sortedUsers() {
      return this.users.map(u => ({ ...u, lastLogin: u.lastLogin ? moment(u.lastLogin).fromNow() : 'Never' }));
    },
    actions() {
      return [
        {
          label: 'Edit',
          icon: 'pencil',
          customRoute: '/company/users/${_id}/edit'
        },
        {
          label: 'Send Welcome Email',
          icon: 'envelope',
          onClick: this.onClickSendWelcome,
          show: ['welcomeEmailSent === false']
        },
        {
          label: 'Reset Password',
          icon: 'key',
          onClick: this.onClickResendPassword,
          show: ['welcomeEmailSent === true']
        },
        {
          label: 'Delete',
          class: 'text-danger',
          icon: 'trash',
          onClick: this.onClickDeleteUser
        }
      ];
    },
    columns() {
      return [
        { name: 'Name', code: 'name', search: true },
        { name: 'Email', code: 'email', search: true },
        {
          name: 'Role',
          code: 'role.name',
          searchCode: 'roleId',
          search: true,
          searchType: 'select2',
          searchOptions: this.roleOptions,
          noSort: true,
          placeholder: 'Select a role...'
        },
        { name: 'Latest Login', code: 'lastLogin' },
        {
          name: 'Welcome Email',
          code: 'welcomeEmailSent',
          type: 'boolean',
          search: true,
          searchType: 'dropdown',
          searchOptions: [
            { label: 'Sent', value: true },
            { label: 'Not Sent', value: false }
          ]
        }
      ];
    },
    roleOptions() {
      return this.roles.map(role => ({ label: role.name, value: role._id }));
    }
  },
  mounted() {
    this.listRoles();
  },
  methods: {
    ...mapActions({
      listRoles: 'role/list',
      listUsers: 'user/list',
      deleteUser: 'company/deleteUser',
      sendWelcomeEmail: 'company/sendWelcomeEmail'
    }),
    onClickDeleteUser(user) {
      this.modals.remove = user;
    },
    async onDeleteUser() {
      const deleteUser = await this.deleteUser({ id: this.$auth.companyId, userSub: this.modals.remove._id });
      console.log(deleteUser);
      if (deleteUser) {
        this.$toasted.success('User deleted successfully!', { position: 'bottom-right', duration: 3000 });
        await this.listUsers({ data: { params: this.usersParams } });
      } else {
        this.$toasted.error('User was not deleted successfully.', { position: 'bottom-right', duration: 3000 });
      }

      this.modals.remove = false;
    },
    async onClickSendWelcome(user) {
      this.modals.welcomeEmail = user;
    },
    async onClickResendPassword(user) {
      this.modals.resendPassword = user;
    },
    async onSendWelcome(user) {
      const response = await this.sendWelcomeEmail({ id: this.$auth.companyId, userSub: user.userSub });

      if (response.message) {
        this.$toasted.success('Successfully sent email.');
        this.sentEmails.push(user.userSub);
      } else {
        this.$toasted.error('Email could not be sent.');
      }

      this.modals.welcomeEmail = false;
      this.modals.resendPassword = false;
    }
  }
};
</script>
